import React from "react";
import { Link } from "gatsby";
import { Container, Row, Col, Image } from "react-bootstrap";
import logo from "../images/logo.webp";
import fb from "../images/fb.svg";
import twitter from "../images/twitter.svg";
import insta from "../images/insta.svg";
import linkedin from "../images/linkedin.svg";

const Footer = () => {
    return (
        <>
            <footer className="footer">
                <Container className="footer__container">
                    <Row className="footer__row">
                        <Col md={6} lg={6} xl={6} className="footer__left-col">
                            <div className="footer__address">
                                <div>
                                    A. &nbsp;30 Fulton Way, Unit 1, Richmond Hill ON L4B 1E6 Canada
                                </div>
                                <div>E. &nbsp;&nbsp;info@moresecure.com</div>
                            </div>
                            <div className="space-1"></div>
                            <div className="footer__social">
                                <div className="footer__follow">FOLLOW US.</div>
                                <a
                                    href="https://fb.me/moresecurecomputing"
                                    className="footer__social-wrapper"
                                >
                                    <Image
                                        className="footer__social-icon"
                                        src={fb}
                                        fluid={true}
                                    />
                                </a>
                                <a
                                    href="https://twitter.com/more_secure"
                                    className="footer__social-wrapper"
                                >
                                    <Image
                                        className="footer__social-icon"
                                        src={twitter}
                                        fluid={true}
                                    />
                                </a>
                                <a
                                    href="https://www.linkedin.com/company/more-secure-computing/"
                                    className="footer__social-wrapper"
                                >
                                    <Image
                                        className="footer__social-icon"
                                        src={linkedin}
                                        fluid={true}
                                    />
                                </a>
                                <a
                                    href="https://www.instagram.com/moresecurecom/"
                                    className="footer__social-wrapper"
                                >
                                    <Image
                                        className="footer__social-icon"
                                        src={insta}
                                        fluid={true}
                                    />
                                </a>
                            </div>
                        </Col>
                        <Col md={6} lg={6} xl={6} className="footer__right-col">
                            <Image src={logo} fluid={true} />
                            <p className="footer__description">
                                More Secure Computing is a Canadian company that provides affordable, effective, and secure online business solutions since 2007. We empower our customers to gain more clientele by providing them the tools they need to maintain a robust and trustworthy business. <br/>
                            
                                We respect your privacy and conduct our business based on <Link to="/Privacy">More Secure Privacy Policy</Link>.
                            </p>
                        </Col>
                    </Row>
                </Container>
            </footer>
            <div className="copyright">
                &copy; COPYRIGHT 2022 | ALL RIGHTS RESERVED
            </div>
        </>
    );
};

export default Footer;
