import React from 'react'
import { Link } from 'gatsby'
import { Layout } from '../components/common'
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";


const Privacy = () => (
    <>
                <NavBar />
                <div className="space-3"></div>
                <div className="space-3"></div>
                <div className="space-3"></div>
        <div className="container privacy">
            <article className="content" style={{ fontSize: `medium` }}>
                <h1 className="content-title" style={{ textAlign: `center` }}>Privacy Policy</h1>
                <section className="content-body">
                <p><strong>Your privacy is very important to us. Accordingly, we have developed this policy in order for you to understand how we collect, use, communicate, and make use of personal information. The following outlines our privacy policy.</strong></p>
<p>We refer https://www.moresecure.com, https://www.moresecure.ca, and their subdomain websites as More Secure websites in this policy document.</p>
<p>When accessing the More Secure websites, More Secure Computing will learn certain information about you during your visit.</p>
<p>Similar to other commercial websites, our website utilizes a standard technology called "cookies" (see explanation below) and server logs to collect information about how our site is used. Information gathered through cookies and server logs may include the date and time of visits, the pages viewed, time spent at our site, and the websites visited just before and just after our own, as well as your IP address.</p>
<h2><strong>Use of Cookies</strong></h2>
<p>A cookie is a very small text document, which often includes an anonymous unique identifier. When you visit a website, that site's computer asks your computer for permission to store this file in a part of your hard drive specifically designated for cookies. Each website can send its own cookie to your browser if your browser's preferences allow it, but (to protect your privacy) your browser only permits a website to access the cookies it has already sent to you, not the cookies sent to you by other sites.</p>
<h2><strong>IP Addresses</strong></h2>
<p>IP addresses are used by your computer every time you are connected to the Internet. Your IP address is a number that is used by computers on the network to identify your computer. IP addresses are automatically collected by our web server as part of demographic and profile data known as &ldquo;traffic data&rdquo; so that data (such as the Web pages you request) can be sent to you.</p>
<h2><strong>Email Information</strong></h2>
<p>If you choose to correspond with us through email, we may retain the content of your email messages together with your email address and our responses. We provide the same protections for these electronic communications that we employ in the maintenance of information received online, mail, and telephone. This also applies when you register for our website, sign up through any of our forms using your email address or make a purchase on this site. For further information see the email policies below.</p>
<h2><strong>How Do We Use the Information That You Provide to Us?</strong></h2>
<p>Broadly speaking, we use personal information for purposes of administering our business activities, providing customer service, and making available other items and services to our customers and prospective customers.</p>
<p>More Secure Computing will not obtain personally-identifying information about you when you visit our site unless you choose to provide such information to us, nor will such information be sold or otherwise transferred to unaffiliated third parties without the approval of the user at the time of collection.</p>
<p>We may disclose information when legally compelled to do so, in other words, when we, in good faith, believe that the law requires it or for the protection of our legal rights.</p>
<h2><strong>Email Policies</strong></h2>
<p>We are committed to keeping your e-mail address confidential. We do not sell, rent, or lease our subscription lists to third parties, and we will not provide your personal information to any third party individual, government agency, or company at any time unless strictly compelled to do so by law.</p>
<p>We will use your e-mail address solely to provide timely information about More Secure Computing.</p>
<p>We will maintain the information you send via e-mail in accordance with applicable federal law.</p>
<p><strong>CAN-SPAM Compliance</strong></p>
<p>In compliance with the CAN-SPAM Act, all e-mail sent from our organization will clearly state who the e-mail is from and provide clear information on how to contact the sender. In addition, all e-mail messages will also contain concise information on how to remove yourself from our mailing list so that you receive no further e-mail communication from us.</p>
<p><strong>Choice/Opt-Out</strong></p>
<p>Our site provides users the opportunity to opt-out of receiving communications from us and our partners by reading the unsubscribe instructions located at the bottom of any e-mail they receive from us at anytime.</p>
<p>Users who no longer wish to receive our newsletter or promotional materials may opt-out of receiving these communications by clicking on the unsubscribe link in the e-mail.</p>
<h2>Use of External Links</h2>
<p>More Secure websites may contain links to many other websites. More Secure Computing cannot guarantee the accuracy of information found at any linked site. Links to or from external sites not owned or controlled by More Secure Computing do not constitute an endorsement by More Secure Computing or any of its employees of the sponsors of these sites or the products or information presented therein.</p>
<p>By accessing this web site, you are agreeing to be bound by these web site Terms and Conditions of Use, all applicable laws and regulations, and agree that you are responsible for compliance with any applicable local laws. If you do not agree with any of these terms, you are prohibited from using or accessing this site. The materials contained in this web site are protected by applicable copyright and trademark law.</p>
<h2>Intellectual Property Rights</h2>
<p>All copyrights, trademarks, patents, and other intellectual property rights in and on our website and all content and software located on the site shall remain the sole property of More Secure Computing or its licensors. The use of our trademarks, content, and intellectual property is forbidden without the express written consent from More Secure Computing.</p>
<p><strong>You must not:</strong></p>
<ul>
<li>Republish material from our website without prior written consent.</li>
<li>Sell or rent material from our website.</li>
<li>Reproduce, duplicate, create derivative, copy or otherwise exploit material on our website for any purpose.</li>
<li>Redistribute any content from our website, including onto another website.</li>
</ul>
<h2>Acceptable Use</h2>
<p>You agree to use our website only for lawful purposes, and in a way that does not infringe the rights of, restrict or inhibit anyone else's use and enjoyment of the website. Prohibited behavior includes harassing or causing distress or inconvenience to any other user, transmitting obscene or offensive content, or disrupting the normal flow of dialogue within our website.</p>
<p>You must not use our website to send unsolicited commercial communications. You must not use the content on our website for any marketing related purpose without our express written consent.</p>
<h2>Restricted Access</h2>
<p>We may in the future need to restrict access to parts (or all) of our website and reserve full rights to do so. If at any point, we provide you with a username and password for you to access restricted areas of our website, you must ensure that both your username and password are kept confidential.</p>
<h2>Use of Testimonials</h2>
<p>In accordance to with the FTC guidelines concerning the use of endorsements and testimonials in advertising, please be aware of the following:</p>
<p>Testimonials that appear on this site are actually received via text, audio, or video submission. They are individual experiences, reflecting real-life experiences of those who have used our products and/or services in some way. They are individual results and results do vary. We do not claim that they are typical results. The testimonials are not necessarily representative of all of those who will use our products and/or services.</p>
<p>The testimonials displayed in any form on this site (text, audio, video, or other) are reproduced verbatim, except for the correction of grammatical or typing errors. Some may have been shortened. In other words, not the whole message received by the testimonial writer is displayed when it seems too lengthy or not the whole statement seems relevant for the general public.</p>
<p>More Secure Computing is not responsible for any of the opinions or comments posted on More Secure websites. More Secure Computing is not a forum for testimonials, however, provides testimonials as a means for customers to share their experiences with one another. To protect against abuse, all testimonials appear after they have been reviewed by management of More Secure Computing. More Secure Computing does not share the opinions, views, or commentary of any testimonials on More Secure websites - the opinions are strictly the views of the testimonial source.</p>
<p>The testimonials are never intended to make claims that our products and/or services can be used to diagnose, treat, cure, mitigate, or prevent any disease. Any such claims, implicit or explicit, in any shape or form, have not been clinically tested or evaluated.</p>
<h2>How Do We Protect Your Information and Secure Information Transmissions?</h2>
<p>Email is not recognized as a secure medium of communication. For this reason, we request that you do not send private information to us by email. However, doing so is allowed, but at your own risk. Some of the information you may enter on our website may be transmitted securely via a secure medium known as Secure Sockets Layer, or SSL. Credit Card information and other sensitive information is never transmitted via email.</p>
<p>More Secure Computing may use software programs to create summary statistics, which are used for such purposes as assessing the number of visitors to the different sections of our site, what information is of most and least interest, determining technical design specifications, and identifying system performance or problem areas.</p>
<p>For site security purposes and to ensure that this service remains available to all users, More Secure Computing uses software programs to monitor network traffic to identify unauthorized attempts to upload or change information, or otherwise cause damage.</p>
<h2>Disclaimer and Limitation of Liability</h2>
<p>More Secure Computing makes no representations, warranties, or assurances as to the accuracy, currency, or completeness of the content contain on this website or any sites linked to this site.</p>
<p>All the materials on this site are provided "as is" without any express or implied warranty of any kind, including warranties of merchantability, non-infringement of intellectual property, or fitness for any particular purpose. In no event shall More Secure Computing or its agents or associates be liable for any damages whatsoever (including, without limitation, damages for loss of profits, business interruption, loss of information, injury or death) arising out of the use of or inability to use the materials, even if More Secure Computing has been advised of the possibility of such loss or damages.</p>
<h2>Social Media Platforms</h2>
<p>Communication, engagement, and actions taken through external social media platforms that this website and its owners participate in are custom to the terms and conditions as well as the privacy policies held with each social media platform respectively.</p>
<p>Users are advised to use social media platforms wisely and communicate/engage upon them with due care and caution in regard to their own privacy and personal details. This website nor its owners will ever ask for personal or sensitive information through social media platforms and encourage users wishing to discuss sensitive details to contact them through primary communication channels such as by telephone or email.</p>
<p>This website may use social sharing buttons that help share web content directly from web pages to the social media platform in question. Users are advised before using such social sharing buttons that they do so at their own discretion and note that the social media platform may track and save your request to share a web page respectively through your social media platform account.</p>
<h2>Policy Changes</h2>
<p>We reserve the right to amend this privacy policy at any time with or without notice. However, please be assured that if the privacy policy changes in the future, we will not use the personal information you have submitted to us under this privacy policy in a manner that is materially inconsistent with this privacy policy, without your prior consent.</p>
<p>We are committed to conducting our business in accordance with these principles in order to ensure that the confidentiality of personal information is protected and maintained.</p>
<h2>Contact</h2>
<p>If you have any questions regarding this policy or your dealings with our website, please contact us here: <a href="https://www.moresecure.com/contact">https://www.moresecure.com/contact</a></p>
                </section>
            </article>
        </div>
                <Footer />
            </>
)

export default Privacy
